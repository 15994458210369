<template>
    <b-card title="YouTube Videos">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-between">
            <b-form-group class="justify-content-end">
                <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block"
                    />
                </div>
            </b-form-group>
            <b-form-group v-if="this.showAdd">
                <div class="d-flex align-items-center">
                    <b-button
                        id="toggle-btn"
                        v-b-modal.modal-prevent-closing
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="addNew()"
                    >
                        <feather-icon
                            icon="PlusIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Add New</span>
                    </b-button>
                </div>
            </b-form-group>
        </div>
    
        <!-- table -->
        <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
                enabled: true,
                externalQuery: searchTerm 
            }"
            :pagination-options="{
                enabled: true,
                perPage:pageLength
            }"
            styleClass="vgt-table striped condensed no-outline"
        >
    
            <template
                slot="table-row"
                slot-scope="props"
            >
    
                <!-- Column: Name -->
                <span
                    v-if="props.column.field === 'title'"
                    class="text-nowrap"
                >
                    <span class="text-nowrap">{{ props.row.title }}</span>
                </span>
        
                <!-- Column: Thumbnail -->
                <span v-else-if="props.column.field === 'thumbnail'">
                    <img style="height: 70px;" :src="host + props.row.thumbnail" alt="">
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'index'">
                    <span>{{props.row.originalIndex+1}}</span>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'" >
                    <span>
                        <!-- <b-button @click="edit(props.row)"
                            v-b-modal.modal-prevent-closing
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="primary" class="btn-icon rounded-circle mr-1"
                            v-if="showEdit"
                        >
                            <feather-icon
                                icon="Edit2Icon"
                            />
                        </b-button> -->
                        <b-button @click="deleteType(props.row)"
                            id="delete-btn"
                            v-b-modal.modal-delete
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="primary" class="btn-icon rounded-circle"
                            v-if="showDelete"
                        >
                            <feather-icon
                                icon="TrashIcon"
                            />
                        </b-button>
                    </span>
                </span>
        
                <!-- Column: Common -->
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
    
            <!-- pagination -->
            <template
                slot="pagination-bottom"
                slot-scope="props"
            >
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                            Showing 1 to
                        </span>
                        <b-form-select
                            v-model="pageLength"
                            :options="['3','5','10','50','100']"
                            class="mx-1"
                            @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                    </div>
                    <div>
                        <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    
        <b-modal
            id="modal-prevent-closing"
            ref="my-modal"
            centered
            :title="getModalTitle()"
            ok-title="Submit"
            cancel-variant="outline-secondary"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
            <form
                ref="form"
                @submit.stop.prevent="handleSubmit"
            >
                <b-form-group
                    :state="nameState"
                    label="Type"
                    label-for="name-input"
                    invalid-feedback="Type is required"
                >
                    <b-form-select v-model="modify.type" :options="options"></b-form-select>
                </b-form-group>

                <b-form-group
                    :state="nameState"
                    label="Title"
                    label-for="name-input"
                    invalid-feedback="Title is required"
                >
                    <b-form-input
                        id="name-input"
                        v-model="modify.title"
                        :state="nameState"
                        required
                    />
                </b-form-group>

                <b-form-group
                    :state="nameState"
                    label="Link"
                    label-for="name-input"
                    invalid-feedback="Link is required"
                >
                    <b-form-input
                        id="name-input"
                        v-model="modify.link"
                        :state="nameState"
                        required
                    />
                </b-form-group>

                <b-form-group
                    label="Thumbnail"
                    label-for="name-input"
                >
                    <b-form-file
                        v-model="modify.thumbnail"
                        placeholder="Choose a image or drop it here..."
                        drop-placeholder="Drop image here..."
                        @change="uploadFile"
                    ></b-form-file>
                </b-form-group>
            </form>
        </b-modal>

        <b-modal
            id="modal-delete"
            ref="modal-delete"
            centered
            modal-class="modal-danger"
            title="Are you sure?"
        >
            <b-card-text class="border-2">
                <span>If you delete, you won't be able to revert this action!!</span>
            </b-card-text>
    
            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="float-right"
                        @click="confirmDelete()"
                    >
                        Accept
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="float-right mr-1"
                        @click="$refs['modal-delete'].toggle('#delete-btn')"
                    >
                        Cancel
                    </b-button>
                </div>
            </template>
        </b-modal>
    </b-card>
</template>
  
<script>
import {
    BAvatar,
    BButton,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BModal,
    VBModal,
    BCardText,
    BFormFile
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
  
export default {
    components: {
        VueGoodTable,
        BAvatar,
        BCard,
        BBadge,
        BButton,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BModal,
        BCardText,
        BFormFile,
        vSelect
    },

    directives: {
        'b-modal': VBModal,
        Ripple,
    },

    computed: {
        ...mapState('ad', ["yt_videos","adError","adLoading", "submitError","submitSuccess","submitLoading"]),
    
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },

    watch: {
        yt_videos(newValue, oldValue) {
            if (newValue) {
                this.rows = this.yt_videos
            }
        }
    },

    data() {
        return {
            host: "https://api-biyeruti.smicee.com",
            nameState: null,
            addNewMode: false,
            popupActive: true,
            modify: {
                type: null,
                link: null,
                title: null,
                thumbnail: null
            },
            options: [
                {
                    text: 'REVIEW',
                    value: 'REVIEW'
                },
                {
                    text: 'PREMIUM',
                    value: 'PREMIUM'
                }
            ],
            pageLength: 5,
            dir: false,
            columns: [
                {
                    label: '#',
                    field: 'index',
                    type:'number',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Title',
                    field: 'title',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Type',
                    field: 'type',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
                {
                    label: 'Thumbnail',
                    field: 'thumbnail',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },
            ],
            delete_id: null,
            showAdd: true,
            showEdit: true,
            showDelete: true,
            rows: [],
            searchTerm: ''
        }
    },

    created() {
        this.$store.dispatch('ad/fetchYTVideos')
        this.rows = this.yt_videos
        let permissions=JSON.parse(localStorage.getItem('permissions'))
        // this.showAdd=permissions.includes("add_state")
        // this.showEdit=permissions.includes("change_state")
        // this.showDelete=permissions.includes("delete_state")
        if(this.showEdit||this.showDelete){
            this.columns.push( {
            label: 'Action',
            field: 'action',
            tdClass: 'text-center',
            thClass:'text-center',
            sortable: false,
            })
        }
    },

    methods: {
        uploadFile(e){
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e =>{
                this.modify.thumbnail = e.target.result;
            };
        },

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            return valid
        },

        resetModal() {
            this.nameState = null
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },

        addNew () {
            this.modify.title = null
            this.modify.type = null
            this.modify.link = null
            this.modify.thumbnail = null
            this.addNewMode = true;
        },

        edit (data) {
            this.addNewMode = false;
            // this.modify.id=data.id
            this.modify.name = data.name;
            // this.popupActive=true;
        },

        async handleSubmit() {
            if (!this.checkFormValidity()) {
                return
            }
            if(this.addNewMode){
                await this.$store.dispatch('ad/addYTVideos', this.modify)
                .then(result=>{
                    if(result.code == 200) {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Success`,
                                icon: 'ThumbsUpIcon',
                                variant: 'success',
                                text: result.response,
                            },
                        })
                    }
                })
            } 
            // else {
            //     await this.$store.dispatch('country/updateCountry',this.modify).then(result=> {
            //         this.$toast({
            //             component: ToastificationContent,
            //             position: 'top-right',
            //             props: {
            //                 title: `Success`,
            //                 icon: 'ThumbsUpIcon',
            //                 variant: 'success',
            //                 text: result,
            //             },
            //         })
            //     })
            // }
            this.$nextTick(() => {
                this.$refs['my-modal'].toggle('#toggle-btn')
            })
        },

        initValues(){
            // this.modify = {
            //     id: null,
            //     name: null,
            // }
        },

        deleteType(data) {
            this.delete_id = data.id
        },

        async confirmDelete(){
            await this.$store.dispatch("ad/deleteYTVideo", this.delete_id).then(result=>{
                if(result.code == 200) {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Success`,
                            icon: 'ThumbsUpIcon',
                            variant: 'success',
                            text: result.response
                        },
                    })
                }
                this.delete_id = null
            })
            this.$nextTick(() => {
                this.$refs['modal-delete'].toggle('#delete-btn')
            })
        },

        getModalTitle(){
            return (this.addNewMode? 'Add New ' : 'Update ') +'YouTube Video'
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import '@core/scss/vue/libs/vue-good-table.scss';
</style>